@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply m-0 h-screen bg-white p-0 text-blue-dark;
  }

  #root {
    @apply m-0 h-full w-full p-0;
  }

  .h2 {
    @apply text-3xl font-bold text-blue-dark;
  }

  .h3 {
    @apply text-2xl font-bold text-blue-mid;
  }
  h5 {
    @apply text-lg font-bold text-blue-dark;
  }

  .input {
    @apply w-full rounded border border-slate-400 bg-white px-4 py-3 text-sm text-secoset-green-900 transition duration-150 ease-linear hover:border-slate-900 disabled:border-slate-400 disabled:opacity-60;
  }

  .saas-input {
    @apply rounded-[4px] border border-[#798EA2] px-4 py-3 text-base;
  }
  .saas-input:disabled {
    @apply bg-[#E2F3F9];
  }
  .saas-input::placeholder,
  .saas-input:disabled {
    @apply font-bold text-[#AEAEAE];
  }
  /* Fix to remove autofill background on saas-input */
  .saas-input:-webkit-autofill,
  .saas-input:-webkit-autofill:hover,
  .saas-input:-webkit-autofill:focus,
  .saas-input:-webkit-autofill:active,
  .saas-input > input:-webkit-autofill,
  .saas-input > input:-webkit-autofill:hover,
  .saas-input > input:-webkit-autofill:focus,
  .saas-input > input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .empty-button {
    @apply rounded border border-secoset-green-900 px-4 py-2 font-semibold text-secoset-green-900 transition duration-150 ease-linear hover:bg-secoset-green-700 hover:text-white disabled:cursor-default disabled:opacity-40 disabled:hover:bg-blue-mid;
  }
  .blue-button {
    @apply rounded-sm border border-transparent bg-blue-semi-dark px-4 py-2 text-center font-semibold text-white transition duration-150 ease-linear hover:border-blue-dark hover:bg-blue-mid hover:text-blue-dark disabled:cursor-default disabled:opacity-40 disabled:hover:bg-blue-mid;
  }
  .yellow-button {
    @apply rounded-sm bg-yellow-mustard px-4 py-2 text-center font-semibold text-blue-dark transition duration-150 ease-linear hover:bg-yellow-light disabled:cursor-default disabled:opacity-40 disabled:hover:bg-yellow-mustard;
  }
}

body {
  font-family: "HK Grotesk", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/hk-grotesk.min.css");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

:root {
  --backgroundColor: #eff6f7;
  --fullBackgroundColor: white;
  --backgroundDarkBlue: #0c5371;
  --backgroundMiddleBlue: #97bac7;
  --backgroundLightBlue: #cfdce3;

  --highlightDiv: var(--backgroundLightBlue);

  --scrolleBarLight: var(--backgroundLightBlue);
  --scrolleBarDark: var(--backgroundMiddleBlue);

  --ProjectListSearchBarBackground: var(--backgroundColor);
  --ProjectListBackground: var(--backgroundColor);

  --callSecoyaBackground: var(--mustard);
  --callSecoyaColor: var(--textDarkBlue);

  --button1Background: var(--backgroundDarkBlue);
  --button1Color: white;
  --button1BorderColor: var(--backgroundDarkBlue);
  --button1BackgroundHover: var(--backgroundMiddleBlue);
  --button1ColorHover: var(--textDarkBlue);
  --button1BorderColorHover: var(--backgroundDarkBlue);
  --button1FontWeight: 600;
  --button1ColorDis: var(--blueFourth);
  --button1BorderColorDis: var(--backgroundLightBlue);

  --button2Background: transparent;
  --button2Color: var(--textJaugeBlue);
  --button2BorderColor: var(--textDarkBlue);
  --button2BackgroundHover: var(--backgroundMiddleBlue);
  --button2ColorHover: var(--textDarkBlue);
  --button2BorderColorHover: "transparent";
  --button2FontWeight: 600;
  --button2ColorDis: var(--textDarkBlue);
  --button2BorderColorDis: var(--backgroundLightBlue);

  --button3Background: var(--mustard);
  --button3Color: var(--textDarkBlue);
  --button3BorderColor: transparent;
  --button3BackgroundHover: var(--mustard_light);
  --button3ColorHover: var(--textDarkBlue);
  --button3BorderColorHover: transparent;
  --button3FontWeight: 600;

  --projectCardBorder: "none";
  --ProjectCardText: var(--backgroundLightBlue);

  --functionnalOk: #35ecb8;
  --functionnalError: #f95620;
  --functionnalFatalError: #ff3333;
  --functionnalMid: #fadf63;

  --textDarkBlue: #103c4b;
  --textMidBlue: #7da1ae;
  --textMidBlue15: #7da1ae15;
  --textLightBlue: #889da5;

  --freePlan: #f95620;
  --labelPlan: #eb9486;
  --customPlan: #7678ed;
  --strategyPlan: #5b2e48;

  --orange: rgb(227, 108, 65);
  --vermilion: #e03616;
  --mustard: #ffdd4a;
  --mustard_light: #fff0ad;

  --buttonSecondary: #b0d7ff;
  --blueBorder: rgba(16, 60, 75, 0.15);

  --input-background: #eff6f6;

  --mainPolice: "HK Grotesk", sans-serif;
}

body.LVMH {
  /* LVMH COLORS */
  --blue: #121426;
  --darkBlue: #0b0d19;
  --sand: #eedfd2;
  --lightSand: #f4efeb;

  --fullBackgroundColor: white;
  --backgroundColor: var(--lightSand);

  --highlightDiv: var(--sand);

  --scrolleBarLight: var(--sand);
  --scrolleBarDark: var(--blue);

  --ProjectListSearchBarBackground: white;
  --ProjectListBackground: var(--backgroundColor);

  --backgroundDarkBlue: var(--blue);
  --backgroundMiddleBlue: var(--darkBlue);
  --callSecoyaBackground: var(--darkBlue);
  --callSecoyaColor: white;
  --mustard: var(--sand);
  --mustard_light: var(--lightSand);

  /* BUTTONS */

  --button1Background: var(--blue);
  --button1Color: white;
  --button1BorderColor: var(--blue);
  --button1BackgroundHover: white;
  --button1ColorHover: var(--blue);
  --button1BorderColorHover: var(--blue);
  --button1FontWeight: 400;
  --button1ColorDis: var(--blue);
  --button1BorderColorDis: var(--lightSand);

  --button2Background: transparent;
  --button2Color: var(--textJaugeBlue);
  --button2BorderColor: var(--textDarkBlue);
  --button2BackgroundHover: var(--blue);
  --button2ColorHover: white;
  --button2BorderColorHover: "transparent";
  --button2FontWeight: 400;
  --button2ColorDis: var(--blue);
  --button2BorderColorDis: var(--lightSand);

  --button3Background: transparent;
  --button3Color: var(--textDarkBlue);
  --button3BorderColor: var(--blue);
  --button3BackgroundHover: var(--blue);
  --button3ColorHover: white;
  --button3BorderColorHover: transparent;
  --button3FontWeight: 400;

  --button3BackgroundHover: var(--blue);
  --button3BorderColorHover: var(--blue);
  --button3FontWeight: 400;

  --projectCardBorder: 1px var(--blue) solid;
  --ProjectCardText: var(--sand);

  --mainPolice: "Jost", sans-serif;
  --textDarkBlue: var(--blue);
}

body.BB {
  /* LVMH COLORS */
  --blue: black;
  --darkBlue: black;
  --sand: lightgray;
  --lightSand: #f5f5f5;

  --fullBackgroundColor: white;
  --backgroundColor: var(--lightSand);

  --highlightDiv: var(--sand);

  --scrolleBarLight: var(--sand);
  --scrolleBarDark: var(--blue);

  --ProjectListSearchBarBackground: white;
  --ProjectListBackground: var(--backgroundColor);

  --backgroundDarkBlue: var(--blue);
  --backgroundMiddleBlue: var(--darkBlue);
  --callSecoyaBackground: var(--darkBlue);
  --callSecoyaColor: white;
  --mustard: var(--sand);
  --mustard_light: var(--lightSand);

  /* BUTTONS */

  --button1Background: var(--blue);
  --button1Color: white;
  --button1BorderColor: var(--blue);
  --button1BackgroundHover: white;
  --button1ColorHover: var(--blue);
  --button1BorderColorHover: var(--blue);
  --button1FontWeight: 400;
  --button1ColorDis: var(--blue);
  --button1BorderColorDis: var(--lightSand);

  --button2Background: transparent;
  --button2Color: var(--textJaugeBlue);
  --button2BorderColor: var(--textDarkBlue);
  --button2BackgroundHover: var(--blue);
  --button2ColorHover: white;
  --button2BorderColorHover: "transparent";
  --button2FontWeight: 400;
  --button2ColorDis: var(--blue);
  --button2BorderColorDis: var(--lightSand);

  --button3Background: transparent;
  --button3Color: var(--textDarkBlue);
  --button3BorderColor: var(--blue);
  --button3BackgroundHover: var(--blue);
  --button3ColorHover: white;
  --button3BorderColorHover: transparent;
  --button3FontWeight: 400;

  --button3BackgroundHover: var(--blue);
  --button3BorderColorHover: var(--blue);
  --button3FontWeight: 400;

  --projectCardBorder: 1px var(--blue) solid;
  --ProjectCardText: lightgray;

  --mainPolice: Arial, Helvetica, sans-serif;
  --textDarkBlue: black;
}

@media (max-width: 800px) {
  #root {
    min-width: 0px;
  }
}

.nav_and_content {
  width: 100%;
  display: grid;
  grid-template-columns: calc(30% - 5px) calc(70% - 5px);
  column-gap: 10px;
  background-color: var(--backgroundColor);
  padding: 10px;
}

h1,
h2,
h3,
h4,
h5,
p,
li {
  color: var(--textDarkBlue);
  font-family: var(--mainPolice);
}

.icon_button {
  display: flex;
  align-items: center;
}

.icon_button:hover {
  cursor: pointer;
}

.icon_button img {
  margin-right: 5px;
}

#loader {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loader p {
  margin-top: 15px;
}

.loader_small_wrapper {
  height: 50px;
}

.loader_small_wrapper #loader {
  min-height: unset;
}

/* textes */

.h1b {
  font-weight: bold;
  font-size: 39.0625px;
  /* line-height: 51px; */
}
.h1r {
  font-weight: normal;
  font-size: 39.0625px;
  /* line-height: 51px; */
}
.h2b {
  font-weight: bold;
  font-size: 30px;
  /* line-height: 41px; */

  /* font-bold text-3xl */
}
.h2r {
  font-weight: normal;
  font-size: 30px;
  /* line-height: 41px; */
}
.h3r {
  font-weight: normal;
  font-size: 25px;
  /* line-height: 33px; */
}

.h3b {
  font-weight: bold;
  font-size: 25px;
  /* line-height: 33px; */

  /* font-bold text-2xl */
}
.h4r {
  font-weight: normal;
  font-size: 20px;
  /* line-height: 26px; */
}
.h4b {
  font-weight: bold;
  font-size: 20px;
  /* line-height: 26px; */

  /* font-bold text-xl */
}
.h5r {
  font-weight: normal;
  font-size: 18px;
  /* line-height: 22px; */
}
.h5b {
  font-weight: bold;
  font-size: 18px;
  /* line-height: 22px; */
}
.hxb {
  font-weight: 700;
  font-size: 16px;
  /* line-height: 21px; */

  /* font-semibold text-base */
}

.hxr {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 21px; */

  /* font-normal text-base */
}
.hzb {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.hzr {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* font-normal text-sm */
}
.hyr {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}
.textMid {
  color: var(--textMidBlue);
}

.input_text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--textDarkBlue);
  font-family: "HK Grotesk", sans-serif;
}

.hxi {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.underline.bold {
  font-weight: 700;
}
.cursor {
  cursor: pointer;
}
.nocursor {
  pointer-events: none;
}
.hover_bold:hover {
  font-weight: bold;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: var(--scrolleBarLight);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrolleBarDark);
  border-radius: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrolleBarLight);
  border-radius: 2px;
}

#header img:not(#logoWrapper) {
  width: 36px;
  height: 36px;
}

/* modals */

.modal_title {
  margin-bottom: 24px;
}

.modal_paragraph {
  margin-bottom: 16px;
}

.modal_check_paragraph {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.modal_check_paragraph img {
  margin-right: 15px;
}

/* FORM */

.TextField__helper-text--error {
  color: var(--functionnalError);
  position: absolute;
  top: 100%;
  position: absolute;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 6px;
}

.MuiDataGrid-columnHeaders ~ div {
  overflow: auto !important;
  height: auto !important;
  width: auto !important;
}

.blink {
  animation-duration: 1s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}
@keyframes clignoter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
